import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    resendEmailVerificationLink(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/auth/send-confirmation-email', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAccountData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/account/settings', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCompanyInfo(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/account/settings/company-info', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTaxAndCurrencyInfo(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/account/settings/tax-and-currency', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateNotificationSettings(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/account/settings/notifications', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateInvoiceDesign(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/account/settings/invoice-design', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changePassword(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/account/change-password', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // subscription
    fetchPaymentCustomer(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/subscription/customer', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCardData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/subscription/payment-method', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // subscription
    fetchSubscription(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/subscription/current', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInvoicesData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/subscription/invoices', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    subscribePlan(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/subscription/subscribe', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addNewCard(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/subscription/add-card', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCard(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/subscription/delete-card', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelSubscription(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/subscription/cancel', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    stripeSession(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/subscription/session', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },

}
